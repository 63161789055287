<article
  class="slider-dialog"
  [class.slider-dialog--landscape]="imageIsLandscape()"
  [class.slider-dialog--portrait]="!imageIsLandscape()"
  [class.has--no-content]="!hasContent()"
  [class.is--loading]="loading()"
>
  <button
    mat-button
    mat-dialog-close
    class="button ms-auto button--primary-subtle slider-dialog-close"
    [class.slider-dialog--landscape]="imageIsLandscape()"
  >
    <span>{{ 'slider-dialog-sluiten-knoptekst' | fromDictionary }}</span>
    <fstr-icon [anchor]="'icon-x'"></fstr-icon>
  </button>

  <div class="slider-dialog__inner" [class.scroll-container]="imageIsLandscape()">
    @if (activeSlide().url) {
      <figure class="slider-dialog__figure">
        <!--  Used for determining landschape/portrait -->

        <img
          class="slider-dialog__image__blurred"
          #img
          [src]="activeSlide().url + '?width=100&mode=max'"
          [alt]="activeSlide()?.alt || activeSlide().title"
          (load)="checkImageOrientation(img, 'load')"
        />

        <img
          #img
          class="slider-dialog__image"
          [ngSrc]="activeSlide().url!"
          [alt]="activeSlide()?.alt || activeSlide().title"
          [width]="1920"
          [height]="1080"
          [sizes]="'100vw, (min-width: 768px) 50vw, (min-width: 1024px) 33.3vw'"
          [loaderParams]="{
            anchor: activeSlide()?.anchor || 'middlecenter',
            scale: 'both',
            width: 1920,
            height: 1080,
            mode: 'max',
          }"
          [attr.data-object-position]="activeSlide()?.anchor || 'middlecenter'"
        />
      </figure>
    }

    @if (hasContent()) {
      <section
        class="w-100 d-flex flex-column p-3 slider-dialog__content"
        [class.pt-md-6]="!imageIsLandscape()"
        [class.scroll-container]="!imageIsLandscape()"
      >
        <div class="p-3">
          @if (activeSlide().title) {
            <h2 class="h3">{{ activeSlide().title }}</h2>
          }
          @if (activeSlide().caption) {
            <ngx-dynamic-hooks
              class="d-block"
              [options]="{ sanitize: false, updateOnPushOnly: true }"
              [content]="activeSlide().caption! | resizeHtmlImages | stripEmbed: cookiePreferences() || undefined"
              fstrDynamicContent
            ></ngx-dynamic-hooks>
          }
        </div>
      </section>
    }
  </div>
</article>

<!-- Start content-slider-dialog nav -->
<nav class="slider-dialog-nav mt-3 gap-4">
  <button class="button button-icon button-outline button--light button--md" (click)="slideTo('prev')">
    <fstr-icon [anchor]="'icon-arrow-left'"></fstr-icon>
  </button>
  <button class="slider-dialog-nav__next" (click)="slideTo('next')">
    <img #img class="slider-dialog-nav__image" [ngSrc]="nextSlide().url!" [alt]="nextSlide()?.alt" [width]="50" [height]="50" />
    <div class="d-flex flex-column lh-sm text-start min-width-0">
      <span class="text-accent fw-bold fs-body-xs">{{ 'slider-dialog-volgende-knoptekst' | fromDictionary }}</span>

      <span class="text-light fw-bold fs-body-md text-truncate">{{ nextSlide().title || nextSlide().caption || nextSlide().alt }}</span>
    </div>
    <div class="button button-icon button--accent button--md">
      <fstr-icon [anchor]="'icon-arrow-right'"></fstr-icon>
    </div>
  </button>
</nav>
