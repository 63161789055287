import { NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  HostListener,
  inject,
  Inject,
  OnInit,
  signal,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { getCookiePreferences, StripEmbedPipe } from '@teamfoster/sdk/cookie-ngrx';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { IconComponent } from '@teamfoster/sdk/icon';
import { Image, ResizeHtmlImagesPipe } from '@teamfoster/sdk/image';
import { SafePipe } from '@teamfoster/sdk/text-utility';
import { DynamicHooksModule } from 'ngx-dynamic-hooks';
import { randomExampleContent } from 'src/app/styleguide/exampledata/example-data';
import { LoadingIndicatorComponent } from '../../components/loading-indicator/loading-indicator.component';
import { Slide } from 'src/app/slider/models';

@Component({
  selector: 'app-content-slider-dialog',
  standalone: true,
  imports: [
    NgOptimizedImage,
    DynamicHooksModule,
    ResizeHtmlImagesPipe,
    StripEmbedPipe,
    SafePipe,
    IconComponent,
    DictionaryNgrxModule,
    MatDialogClose,
    LoadingIndicatorComponent,
  ],
  templateUrl: './content-slider-dialog.component.html',
  styleUrl: './content-slider-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentSliderDialogComponent implements OnInit {
  private store = inject(Store);
  cookiePreferences = this.store.selectSignal(getCookiePreferences);

  activeIndex = signal(0);

  data: { slides: Slide[]; activeIndex: number } = inject(MAT_DIALOG_DATA);

  slides = this.data.slides;

  activeSlide = computed(() => this.slides[this.activeIndex()]);

  nextSlide = computed(() => {
    const nextIndex = this.activeIndex() + 1;
    return this.slides[nextIndex < this.slides.length ? nextIndex : 0];
  });

  prevSlide = computed(() => {
    const prevIndex = this.activeIndex() - 1;
    return this.slides[prevIndex >= 0 ? prevIndex : this.slides.length - 1];
  });

  hasContent = computed(() => this.activeSlide().title || this.activeSlide().caption);

  ngOnInit(): void {
    console.log('gets inited', this.data);
    this.activeIndex.set(this.data.activeIndex);
  }

  imageIsLandscape = signal(false);
  loading = signal(true);
  cd = inject(ChangeDetectorRef);

  checkImageOrientation(img: HTMLImageElement, type: string) {
    this.imageIsLandscape.set(img.naturalWidth > img.naturalHeight);
    this.loading.set(false);
    this.cd.detectChanges();
  }

  @HostListener('document:keydown.arrowRight', ['$event'])
  next() {
    this.slideTo('next');
  }

  @HostListener('document:keydown.arrowLeft', ['$event'])
  prev() {
    this.slideTo('prev');
  }

  slideTo(direction: 'next' | 'prev') {
    this.activeIndex.set(
      direction === 'next'
        ? (this.activeIndex() + 1) % this.slides.length
        : (this.activeIndex() - 1 + this.slides.length) % this.slides.length
    );
  }
}
