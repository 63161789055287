@if (slider$()?.title || slider$()?.intro) {
  <div class="mb-3 mb-md-4 text-max-width-sm">
    @if (slider$()?.title; as title) {
      <h2 class="mb-0">{{ title }}</h2>
    }
    <!--@if (slider$()?.intro; as intro) {
      <p class="mb-0 mt-2">{{ intro }}</p>
    }-->
  </div>
}

<div class="content-slider">
  @if (loading$()) {
    <section class="text-center text-max-width-sm mx-auto my-5">
      <app-loading-indicator class="text-secondary" [loading]="true" [type]="'standalone'"></app-loading-indicator>

      <p class="mb-0 mt-3 text-body-secondary fs-body-sm fw-normal">{{ 'content-slider-laden-tekst' | fromDictionary }}</p>
    </section>
  }
  <div class="swiper-container-wrapper">
    <div class="swiper-container" (mousewheel)="preventBack($event)">
      <swiper-container class="swiper" init="false" #swipercontainer>
        @for (slide of slider$()?.slides; track $index; let i = $index) {
          <swiper-slide class="swiper-slide content-slide" #swipeslide (click)="openModal($index)">
            <fstr-image
              class="content-slide__image"
              [class.cursor-pointer]="hasDialog()"
              [colSpan]="{
                xs: 12,
                sm: 5,
                md: 3,
              }"
              [image]="slide"
              [width]="1200"
              [height]="1200"
              [mode]="'max'"
            ></fstr-image>

            @if (slide.caption) {
              <div class="content-slide__caption">
                <p class="caption max-lines-2 mb-1">
                  {{ slide.caption }}
                </p>
                @if (hasDialog()) {
                  <span class="button button-link button-xxs button--primary h-auto border-0 d-md-none">
                    {{ 'slider-open-details' | fromDictionary }}
                  </span>
                }
              </div>
            }

            @if (hasDialog()) {
              <span class="chip chip--primary chip--lg content-slide__chip d-none d-md-flex pointer-events-none">
                <fstr-icon anchor="line-icon-eye"></fstr-icon>
              </span>
            }
          </swiper-slide>
        }
      </swiper-container>
    </div>

    @if (!loading$()) {
      @if (!swiper()?.isBeginning) {
        <button class="button button-icon button--accent button--xl swiper-button-prev bg-accent" (click)="swiper()?.slidePrev()">
          <fstr-icon anchor="line-icon-arrow-left"></fstr-icon>
          <span class="sr-only">Vorige</span>
        </button>
      }

      @if (!isEnd) {
        <button class="button button-icon button--accent button--xl swiper-button-next bg-accent" (click)="swiper()?.slideNext()">
          <fstr-icon anchor="line-icon-arrow-right"></fstr-icon>
          <span class="sr-only">Volgende</span>
        </button>
      }
    }
  </div>
</div>

<!--@if (slider$()?.slides?.[0]; as slide) {
  <div class="gallery-slide">
    <fstr-image [image]="slide" [width]="1200" [height]="1200" [mode]="'max'"></fstr-image>

    @if (slide.caption) {
      <div class="swiper-slide__caption mt-2">
        <p class="mb-0 caption ps-2 text-max-width-xs">{{ slide.caption }}</p>
      </div>
    }
  </div>
}-->
